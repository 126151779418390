.sider {
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
  width: 200px;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0px 8px 0px;
  background-color: #001629 !important;
}

.header {
  z-index: 1;
  display: block !important;
  background-color: transparent !important;
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.logoContainer {
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  transition: height 0.2s linear;

  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.collapsed {
    height: 64px;
  }
}

.menu {
  margin-top: 64px;
  height: 100vh;
}
.logoutButton {
  background-color: #e14d4d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 24px;
  margin-bottom: 72px;

  @media screen and (max-width: 768px) {
    margin: 16px;
    margin-bottom: 72px;

  }
}
.logoText {
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
}

.cronStatus, .totalUserCount{
  margin-bottom: 8px;
  font-weight: bold;
  color: white;
}
