.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 24px;


    .logo {
        width: 120px;
        height: 120px;
        margin-bottom: 36px;
    }
}
